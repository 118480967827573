
var toggle = document.querySelector('.toggle-nav');
var toggleIcon = document.querySelector('.mobile-toggle');
var hex = document.querySelector('.hex');

var menu = document.querySelector('.nav-primary');

toggle.addEventListener('click', function () {
  if (toggleIcon.classList.contains('open')) {
    toggleIcon.classList.remove('open');
    menu.classList.remove('open');
    hex.classList.remove('hide');

    menu.classList.remove('slideInDown');
    menu.classList.add('flipOutY');

  } else {
    toggleIcon.classList.add('open');
    menu.classList.add('open');
    menu.classList.add('slideInDown');
    menu.classList.remove('flipOutY');
    hex.classList.add('hide');


  }
});

jQuery(document).ready(function ($) {
  // function resetNav() {
  //   var windowWidth = $(window).width();
  //   if (windowWidth > 782) {
  //     $('nav.nav-primary').removeClass('mobile').addClass('desktop').show();
  //     $('.navbar').append($('nav.nav-primary'));
  //   } else {
  //     $('nav.nav-primary').removeClass('desktop').addClass('mobile').hide();
  //     $('nav.nav-primary ul.menu > li > ul.sub-menu').hide();
  //     $('header').before($('nav.nav-primary'));
  //   }
  //   $('nav.nav-primary ul.menu > li > ul.sub-menu').hide();
  // }






  function heroParalax() {
    var windowWidth = $(window).width();
    if (windowWidth > 782) {
      var scrollPosition = $(window).scrollTop();

      var $heroContent = $('#hero .para');
      var textY = scrollPosition * +0.015;

      $heroContent.css({
        '-webkit-transform': 'translate3d(0,' + textY + 'em, 0)',
        '-moz-transform': 'translate3d(0,' + textY + 'em, 0)',
        '-ms-transform': 'translate3d(0,' + textY + 'em, 0)',
        '-o-transform': 'translate3d(0,' + textY + 'em, 0)',
        'transform': 'translate3d(0,' + textY + 'em, 0)'
      });

      var $heroContent2 = $('#hero .overlap img, .block .overlap');
      var overlapY = scrollPosition * +0.005;

      $heroContent2.css({
        '-webkit-transform': 'translate3d(0,' + overlapY + 'em, 0)',
        '-moz-transform': 'translate3d(0,' + overlapY + 'em, 0)',
        '-ms-transform': 'translate3d(0,' + overlapY + 'em, 0)',
        '-o-transform': 'translate3d(0,' + overlapY + 'em, 0)',
        'transform': 'translate3d(0,' + overlapY + 'em, 0)'
      });
    } else {
      $('#hero .para, #hero .overlap img, .block .overlap').css({
        '-webkit-transform': 'translate3d(0, 0, 0)',
        '-moz-transform': 'translate3d(0, 0, 0)',
        '-ms-transform': 'translate3d(0, 0, 0)',
        '-o-transform': 'translate3d(0, 0, 0)',
        'transform': 'translate3d(0, 0, 0)'
      });
    }
  }

  function hideHeader() {
    var scrollPos = $(window).scrollTop();
    var windowWidth = $(window).width();
    if (scrollPos>140 && windowWidth>782) {
      $('.navbar').fadeOut();
    } else if (scrollPos<140 || windowWidth<782) {
      $('.navbar').fadeIn();
    }
  }

  $('.focuspoint img').on('lazyload', function(e) {
    $(this).parent().focusPoint();
  });

  $('.fancybox').fancybox({
    padding: 0,
  });

  $('select').select2({
    minimumResultsForSearch: -1
  });

  var category_select = $( "#category_select" );
  var filter_select = $( "#filter_select" );
  var category_clone = $( "#category_select" ).contents();

  $( category_select ).prop("disabled", true);

  $(filter_select).change( function(){
    var filter_type = parseInt( $(this).val() );

    if ( filter_type === 0 ) {
      $( category_select ).prop("disabled", true);
    } else {
      $( category_select ).empty().append( category_clone.clone() ).prop("disabled", true);

      $( category_select ).children('option').each( function(){
        var filter_id = parseInt( $(this).data('parent-filter') );
        if( filter_id !== filter_type && filter_id !== 0 ){
          $(this).remove();
        }
      });
      $( category_select ).prop("disabled", false);
    }
  });

  $('.return-to-top').click(function(){
    $('html, body').animate({scrollTop : 0}, 'fast');
    return false;
  });

  // $('.toggle-nav').click(function(){
  //   $('nav.nav-primary').slideToggle('fast');
  // });

  heroParalax();
  hideHeader();
  resetNav();
  mobileNav();

  $(window).on("scroll", function(){
    hideHeader();
    heroParalax();
  });

  $(window).on("resize", function(){
    resetNav();
    heroParalax();

    $('select').select2({
      minimumResultsForSearch: -1
    });
  });
});